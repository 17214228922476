<template>
  <div class="basic">
    <div class="name">基本资料</div>
    <div class="datawrap">
      <div class="imgbox">
        
        <div class="shadow" @mousemove="mousemove" @mouseout="mouseout">
          <img src="../../../assets/image/avatar.jpg" alt="">
          <!-- <div :class="{layer:isShow}">编辑资料</div> -->
          </div>
        
      </div>
      <div class="databox">
        <div>{{ruleForm.name}}</div>
        <div>{{user.mobile | phoneFmt}}</div>
        <!-- <div class="world">资料完整度：
          <el-progress :text-inside="true" :stroke-width="15" :percentage="80" status="success"></el-progress>
        </div> -->
      </div>
    </div>
    <div class="titename">个人信息</div>
    <el-form  ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px">
      <el-form-item label="昵称：">
        <el-input v-model="ruleForm.name" placeholder="请设置您的昵称"></el-input>
      </el-form-item>
      <el-form-item label="绑定手机：">
        <span>{{user.mobile | phoneFmt}}</span>
        <span class="verified"> （已验证）</span>
        <!-- <router-link to="/Phonebind" class="modify">修改</router-link> -->
      </el-form-item>
      <el-form-item label="我的邮箱：" prop="email">
        <el-input v-model="ruleForm.email" placeholder="请输入您的公司邮箱"></el-input>
      </el-form-item>
       <div class="titename">公司信息</div> 
      <el-form-item label="公司名称：">
        <el-input v-model="ruleForm.companyName" placeholder="请输入您所在公司名称"></el-input>
      </el-form-item>
      <el-form-item label="公司地址：">
        <el-input v-model="ruleForm.address" placeholder="请输入您所在公司地址"></el-input>
      </el-form-item>
      <el-form-item label="电话/手机：" >
        <el-input v-model="ruleForm.phone" placeholder="请输入所在公司电话/手机"  maxlength="11" oninput = "value=value.replace(/[^\d]/g,'')"></el-input>
      </el-form-item>
      <!-- <el-form-item label="公司传真：">
        <el-input v-model="form.fax" placeholder=" 请输入所在公司传真"></el-input>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'basic',
  data() {
    
    return {

      timer:"",
      isShow:false, //是否展示头像朦层
      ruleForm: {
        name: '', //用户昵称
        email: '', //邮箱
        companyName: '', //公司名称
        address: '', //公司地址
        phone: '', //公司电话
        // fax: '',
      },
       rules: {
          email: [
            // { required: true, message: '请输入邮箱地址', trigger: 'blur' },
			{ type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
          ]
          
        }

    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  components: { },
  created() {
    this.getInfo();
  },
  mounted() {
    if (this.timer!="") {
      clearTimeout(timer);
    }
  },
  methods: {
  mousemove(){
    this.isShow=true;
  },
  mouseout(){
    this.isShow=false;
  },
    //  submitForm(formName) {
    //   //  alert("$4")
    //     this.$refs[formName].validate((valid) => {
    //       console.log(valid,444)
    //       if (!valid) {
    //         alert('submit!');
    //       } else {
    //         console.log('error!!');
    //         return false;
    //       }
    //     });
    //   },
     

    getInfo(){
      let self=this;
        this.$http.get('/buyer/getBuyerinfo').then(res=>{ 
          let obj=res.data.data;
          self.ruleForm.address=obj.address;
          self.ruleForm.phone=obj.bindTel;
          self.ruleForm.companyName=obj.companyName;
          self.ruleForm.email=obj.email;
          self.ruleForm.name=obj.nickName;
         })
    },
    submitForm(formName) { //提交保存
    this.$refs[formName].validate((valid) => {
      if(!valid){
        this.$message({
          message: '请输入正确的邮箱地址！',
          type: 'warning'
        });
        return;
      }

      // 如果邮箱地址正确并且有没输入的
      if(this.ruleForm.name==""&&this.ruleForm.email==""&&this.ruleForm.companyName==""&&this.ruleForm.address==""&&this.ruleForm.phone==""){
        this.$message({
          message: '输入信息不能为空！',
          type: 'warning'
        });
        return;  
      }else{
              let Params={ "address": this.ruleForm.address,
                        "bindTel": this.ruleForm.phone,
                        "companyName": this.ruleForm.companyName,
                        "email": this.ruleForm.email,
                        "nickName": this.ruleForm.name}
              this.$http.post('/buyer/addBuyerInfo',Params).then(res=>{
                if(res.data.code==0||res.data.code==200){
                  let self=this;
                   this.getInfo(); 
                   this.$message({
                      message: '资料修改成功！',
                      type: 'success'
                    });
                  this.timer= setTimeout(()=>{
                    this.$router.push("/personOrder");
                  },1500) 
                }
                 
              })
           }
     });
      
     
    }
  }
}

</script>


<style lang='scss' scoped>
  @import "../../../assets/scss/common.scss";
  @import "../../../assets/scss/reset.scss";
  .basic{
    padding: 30px 20px;
    .name{
      font-size: 18px;
      color: #333333;
      padding-bottom: 25px;
    }
    .datawrap{
      overflow: hidden;
      padding-bottom: 20px;
      border-bottom: 1px solid #eaeaea;
      .imgbox{
        float: left;
        width: 90px;
        height: 90px;
        position: relative;
        margin-right: 20px;
        img{
          width: 100%;
          height: 100%;
        }
        .shadow{
          width: 90px;
          height: 90px;
          border-radius: 50%;
          overflow: hidden;
          color: #ffffff;
          background-color: #d82229;
          cursor: pointer;
          text-align: center;
          line-height: 90px;
          position: relative;
          top: 0;
          left: 0;
        }
        .layer{
          width: 100%;
          height:100%;
          background: #000;
          opacity: 0.3;
          top: 0;
          position: absolute;
        }
      }
      .databox{
        float: left;
        width: 240px;
        padding: 25px 0 0;
        .world{
          font-size: 13px;
          margin-top: 10px;
          .el-progress{
            left: 75px;
            top: -15px;
          }
        }
      }
    }
    .titename {
      border-left: 3px solid #d82229;
      font-size: 16px;
      padding-left: 10px;
      margin: 25px 0;
    }
    /deep/.el-form{
      .el-form-item__content{
         width: 278px;
         float: left;
         margin-left: 0px !important;
         span{
           font-size: 16px;
         }
         .verified{
            font-size: 12px;
         }
         .modify{
           font-size: 14px;
           color: #d82229;
           margin-left: 10px;
         }
         .el-button--primary{
            color: #ffffff;
            background-color: #d82229;
            border-color: #d82229;
            margin-left: 140px;
         }
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"]{
    -moz-appearance: textfield;
  }

</style>
